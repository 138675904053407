import * as React from "react";
import useTitle from "../../hooks/useTitle";
import artwork from "../../assets/artwork.json";
import shuffle from "../../util/shuffle";
import "../../style/home.css";
import FrontPageSlider from "../components/FrontPageSlider";

function getRandomArtworks(count: number) {
  return shuffle(artwork).slice(0, count);
}

function Home() {
  useTitle('');
  const randomArt = getRandomArtworks(12);
  const leftArt = randomArt.slice(0, 6);
  const rightArt = randomArt.slice(6, 12);

  return (
    <div className={'home-art'}>
      <FrontPageSlider art={leftArt}/>
      <FrontPageSlider art={rightArt}/>
    </div>
  );
}

export default Home;
