import * as React from "react";
import {Link} from "react-router-dom";
import logo from '../../assets/images/logo.gif';

function Title() {
  return (
    <h1 className={'logo'} style={{backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat'}}>
      <Link to={'/'}>Dennis J Ziegler</Link>
    </h1>
  );
}

export default Title;
