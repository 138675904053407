import * as React from "react";
import useTitle from "../../hooks/useTitle";
import "../../style/contact.css";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {FormEvent, useEffect, useState} from "react";
import axios from "axios";
import useInterval from "../../hooks/useInterval";
import ErrorMessage from "../components/ErrorMessage";
import SuccessMessage from "../components/SuccessMessage";

function Contact() {
  useTitle('Contact Us');

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [notes, setNotes] = useState('');
  const [errors, setErrors] = useState<string[] | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useInterval(() => {
    if (token === '') {
      return;
    }
    setToken('');
  }, 150000);

  useEffect(
    () => {
      if (!executeRecaptcha) {
        return;
      }
      if (token !== '') {
        return;
      }
      executeRecaptcha('contact_page').then(captcha => {
        setToken(captcha)
      });
    },
    [token, executeRecaptcha]
  );

  const closeError = () => {
    setErrors(null);
  };

  const closeSuccess = () => {
    setName('');
    setEmail('');
    setNotes('');
    setToken('');
    setSuccess(null);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      name,
      email,
      notes,
      token,
    };

    axios({
      method: 'post',
      url: `https://backend.djzart.com/api/send-contact`,
      // url: `http://localhost:8282/api/send-contact`,
      headers: { 'content-type': 'application/json' },
      data: body
    })
      .then(result => {
        setSuccess('Thank you for your question. We will be in touch shortly.');
      })
      .catch(
        err => {
          if (err.response.status === 422) {
            setErrors(err.response.data.validation_errors);
          } else {
            setErrors([`Error: ${err.message}`]);
          }
        }
      );
  }

  return (
    <div className={'contact'}>
      {
        errors !== null ?
          (<ErrorMessage
            title={'Oops! Something went wrong.'}
            errors={errors}
            close={closeError}
          />)
          : null
      }
      {
        success !== null ?
          (<SuccessMessage title={'Thank you'} message={success} close={closeSuccess}/>)
          : null
      }
      <p>If you have questions about the art, the artist or the site, please contact us. We would be happy to hear from
        you.</p>
      <form onSubmit={onSubmit}>
        <label htmlFor={'name'}>
          Your name
        </label>
        <input
          type={'text'}
          name={'name'}
          id={'name'}
          required={true}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <label htmlFor={'email'}>
          Your email address
        </label>
        <input
          type={'email'}
          name={'email'}
          id={'email'}
          required={true}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor={'question'}>
          Question
        </label>
        <textarea
          rows={24}
          cols={80}
          name={'question'}
          required={true}
          id={'question'}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        <input type='hidden' name={'token'} value={token} readOnly={true} />
        <button type={'submit'}>Send Question</button>
      </form>
    </div>
  );
}

export default Contact;
