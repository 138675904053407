import * as React from "react";
import {useEffect} from "react";
import "../../style/error-message.css";

interface ErrorProps {
  errors: string[],
  title: string,
  close: () => void,
}

function ErrorMessage({errors, title, close}: ErrorProps) {
  useEffect(() => {
    const escape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close();
      }
    }

    document.addEventListener("keydown", escape, false);

    return () => {
      document.removeEventListener("keydown", escape, false);
    };
  }, [close]);

  return (
    <div className={'error-message'}>
      <span onClick={() => close()} className={'close-button'}>x</span>
      <div className={'title'}>{title}</div>
      <ul>
        {
          errors.map((err, i) => {
            return <li key={i}>{err}</li>
          })
        }
      </ul>
    </div>
  );
}

export default ErrorMessage;
