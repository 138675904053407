import * as React from "react";
import {useState} from "react";
import Artwork from "../../interfaces/artwork";
import useInterval from "../../hooks/useInterval";
import "../../style/front-page-slider.css";

interface FrontPageSliderProps {
  art: Artwork[]
}

function FrontPageSlider({art}: FrontPageSliderProps) {
  const [imageNumber, setImageNumber] = useState(0);
  useInterval(() => {
    setImageNumber((imageNumber + 1) % art.length);
  }, 3000);

  return (
    <div className={'slider-container'}>
      <div className={'slider'}>
        {art.map((a, i) => {
          let opacity = '';
          if (i === imageNumber) {
            opacity = 'opaque';
          }
          let image = a.images.filter(i => i.name === 'index_image')[0];
          return (<img src={image.image_url} alt={image.alt_text} key={image.image_id} className={opacity}/>);
        })}
      </div>
    </div>
  );
}

export default FrontPageSlider;
