import * as React from "react";
import {Link} from "react-router-dom";
import drawings from "../../assets/images/drawings.gif";
import paintings from "../../assets/images/paintings.gif";
import contact from "../../assets/images/contact.gif";
import about from "../../assets/images/about.gif";

function Menu() {
  return (
    <div>
      <nav>
        <ul className={'nav'}>
          <li className={'drawings'}>
            <Link to={'/drawings'} style={{background: `url(${drawings}) no-repeat`}}>Drawings</Link>
          </li>
          <li className={'paintings'}>
            <Link to={'/paintings'} style={{background: `url(${paintings}) no-repeat`}}>
              Paintings
            </Link>
          </li>
          <li className={'contact'}>
            <Link to={'/contact'} style={{background: `url(${contact}) no-repeat`}}>
              Contact
            </Link>
          </li>
          <li className={'about'}>
            <Link to={'/about'} style={{background: `url(${about}) no-repeat`}}>
              About
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Menu;
