import React from 'react';
import '../style/App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Drawings from "./pages/Drawings";
import Paintings from "./pages/Paintings";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Title from "./components/Title";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import Purchase from "./pages/Purchase";

function App() {
  return (
    <Router>
      <div className={'app'}>
        <Title />
        <Menu />
        <div className={'content'}>
          <Switch>
            <Route path={'/drawings'}><Drawings/></Route>
            <Route path={'/paintings'}><Paintings/></Route>
            <Route path={'/contact'}><Contact/></Route>
            <Route path={'/about'}><About/></Route>
            <Route path={'/gallery/:id'}><Gallery/></Route>
            <Route path={'/purchase/:id'}><Purchase/></Route>
            <Route path={'/'}><Home/></Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
