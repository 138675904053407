import * as React from "react";
import {Link, useParams} from "react-router-dom";
import useTitle from "../../hooks/useTitle";
import artwork from "../../assets/artwork.json";
import getImageByType from "../../util/getImageByType";
import "../../style/gallery.css";
import getArtworkByWebKey from "../../util/getArtworkByWebKey";


function Gallery() {
  useTitle('');
  const {id} = useParams<{ id: string }>();

  const art = getArtworkByWebKey(artwork, id);
  const image = getImageByType(art.images, 'full_size');
  return (
    <div className={'gallery-details'}>
      <div className={'title'}>
        "{art.title}" - #{art.art_number}
      </div>
      <div className={'art-details'}>
        <div className={'image overlay'}>
          {
            art.original_sold ? <img src="/images/sold_overlay.png" alt="sold" className="soldoverlay"/> : null
          }
          <img src={image.image_url} alt={image.alt_text}/>
        </div>
        <div className={'art-info'}>
          <div className={'size'}>
            {art.height}" x {art.width}"
          </div>
          <div className={'materials'}>
            {art.medium}
          </div>
          <div className={'price'}>
            ${art.original_price}
          </div>
          {
            art.original_sold ? <div className={'purchase'}>SOLD</div> :
              <div className={'purchase'}>
                <Link to={`/purchase/${id}`}>contact to purchase</Link>
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Gallery;
