import * as React from "react";

function Footer() {
  return (
    <div className={'footer'}>
      <div className={'copyright'}>
        <div>{`Dennis J Ziegler Art Copyright © ${new Date().getFullYear()}`}</div>
        <div>All Rights Reserved</div>
      </div>
    </div>
  );
}

export default Footer;
