import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import useTitle from "../../hooks/useTitle";
import "../../style/purchase.css";
import {useParams} from "react-router-dom";
import getImageByType from "../../util/getImageByType";
import getArtworkByWebKey from "../../util/getArtworkByWebKey";
import artwork from "../../assets/artwork.json";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import axios from "axios";
import useInterval from "../../hooks/useInterval";
import ErrorMessage from "../components/ErrorMessage";
import SuccessMessage from "../components/SuccessMessage";

function Purchase() {
  useTitle('');
  const {id} = useParams<{ id: string }>();

  const art = getArtworkByWebKey(artwork, id);
  const image = getImageByType(art.images, 'full_size');

  const {executeRecaptcha} = useGoogleReCaptcha();
  const [token, setToken] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [notes, setNotes] = useState('');
  const [errors, setErrors] = useState<string[] | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useInterval(() => {
    if (token === '') {
      return;
    }
    setToken('');
  }, 150000);

  useEffect(
    () => {
      if (!executeRecaptcha) {
        return;
      }
      if (token !== '') {
        return;
      }
      executeRecaptcha('purchase_page').then(captcha => {
        setToken(captcha)
      });
    },
    [token, executeRecaptcha]
  );

  const closeError = () => {
    setErrors(null);
  }

  const closeSuccess = () => {
    setToken('');
    setName('');
    setEmail('');
    setNotes('');
    setPhone('');
    setSuccess(null);
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      name,
      email,
      phone,
      notes,
      token,
      piece: art.title
    };

    axios({
      method: 'post',
      url: `https://backend.djzart.com/api/send-purchase`,
      // url: `http://localhost:8282/api/send-purchase`,
      headers: {'content-type': 'application/json'},
      data: body
    })
      .then(result => {
        setSuccess('Thank you for your purchase inquiry. We will be in touch shortly.');
      })
      .catch(err => {
        if (err.response.status === 422) {
          setErrors(err.response.data.validation_errors);
        } else {
          setErrors([`Error: ${err.message}`]);
        }
      });
  }

  return (
    <div className={'purchase'}>
      {
        errors !== null ?
          (<ErrorMessage
            title={'Oops! Something went wrong.'}
            errors={errors}
            close={closeError}
          />)
          : null
      }
      {
        success !== null ?
          (<SuccessMessage title={'Thank you'} message={success} close={closeSuccess}/>)
          : null
      }
      <figure key={image.image_id}>
        <figcaption>"{art.title}"</figcaption>
        <img src={image.image_url} alt={image.alt_text}/>
      </figure>
      <form onSubmit={onSubmit}>
        <label htmlFor={'name'}>
          Your name
        </label>
        <input
          type={'text'}
          name={'name'}
          required={true}
          id={'name'}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <label htmlFor={'email'}>
          Email
        </label>
        <input
          type={'email'}
          name={'email'}
          required={true}
          id={'email'}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor={'phone'}>
          Phone number
        </label>
        <input
          type={'tel'}
          name={'phone'}
          pattern={'\\(?[0-9]{3}\\)?-? *[0-9]{3}-?[0-9]{4}'}
          placeholder={'xxx-xxx-xxxx'}
          required={true}
          id={'phone'}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />

        <label htmlFor={'notes'}>
          Notes
        </label>
        <textarea
          rows={24}
          cols={80}
          name={'notes'}
          required={true}
          id={'notes'}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        <input type={'hidden'} name={'piece'} value={art.title}/>
        <input type='hidden' name={'token'} value={token} readOnly={true}/>
        <button type={'submit'}>I would like to purchase this piece</button>
      </form>
    </div>
  );
}

export default Purchase;
