import * as React from "react";
import dennis from "../../assets/images/dennis.jpg";
import useTitle from "../../hooks/useTitle";

function About() {
  useTitle('About Dennis John Ziegler Art');

  return (
    <div className={'large'}>
      <img src={dennis} alt={'Dennis J Ziegler'} className={'dennis'}/>
      <p>The artist Dennis John Ziegler was born on March 27, 1948 in Grand
        Forks, North Dakota, a small university town located on the windswept
        high central plains, an outpost closer to Canada than to the big city of
        Fargo. His father, Conrad Joseph Ziegler, was a law student at the
        University of North Dakota at the time of Dennis's birth. Conrad worked
        two jobs while completing law school on GI Bill benefits earned by
        crawling up Omaha Beach in Normandy under constant machine gun fire and
        enduring heavy combat in places like the Battle of the Bulge and the
        Arden Forest. Dennis's father endured this incredibly violent background
        experience just a few years years before the artist was born. Conrad
        became a lawyer, district attorney, and judge in his long legal career
        in North Dakota. Dennis's mother Ann was a farm girl from nearby
        Crookston, Minnesota. She raised ten children with husband Conrad. There
        was never enough time for anything except work and duty for the parents,
        but somehow their own unrequited love of culture, travel, art, and music
        (Ann played one or two real mean ditties on the piano) was obvious to
        the children, despite the long hours the parents spent securing
        necessities and the limited opportunities for young children with
        artistic inclinations. If Dennis's mother were alive today, she might
        explain that she pushed the crayons at him to keep him from driving her
        nuts. There was no Ritalin back then, and only two channels on the
        television.</p>
      <p>Dennis is the oldest of 10 children, the untimely placement of which
        caused great expectations of him by his parents, with few or none of
        those expectations running in the direction of him becoming an artist.
        The tensions involved in the rich drama of his childhood, some of them
        resolved or partially-resolved and some of them still in tatters, are
        apparent in many of his works. He began drawing and sketching from the
        first time he discovered pencil and paper. It was love at first sight.
        This early powerful affinity has never left him, despite detours into
        many other directions. Creating original artworks has been his great
        consuming passion for six decades. Dennis classifies himself as a Folk
        Abstractionist. In moments of self-effacement, he refers to his drawings
        as "advanced doodling."</p>
      <p>After graduating from high school in 1966, Dennis surprised everyone
        by going for a stint in the U.S. Army. He became an Army medic, and was
        stationed in Germany for two years. He returned to the U.S. after his
        time in the military and made his way to San Francisco (hey, it was
        still the 1960's), where he worked in various construction and finish
        carpentry jobs to support himself while he got back to the business of
        his education. He also found time, despite working and studying seven
        days a week, to perform at a San Francisco playhouse run by followers of
        George Gurdjieff, a man whose philosophy continues to influence the
        artist today. Dennis attended the University of California-Berkeley in
        the 1970's, studying history, art, and philosophy. When he finally
        received his degree from Berkeley many years later (the delay being just
        another long and strange story in the life of an eclectic personality),
        he graduated Summa Cum Laude.</p>
      <p>He met a girl in San Francisco while working at the theater, and
        Barbara and Dennis married in 1976. They had one child, a boy named
        Sebastian, born in 1978. Dennis and Barbara moved to New Jersey near
        Barbara's original home to raise their son, but were later divorced.
        Fast forwarding, Sebastian and Barbara have lived in California for much
        of the last 10 years, and Dennis lives in virtual seclusion in rural New
        Mexico. Dennis's devotion to art is matched only by his devotion to
        Sebby.</p>
      <p>Dennis supported himself with construction and finish carpentry work
        until he retired in 2010 to devote himself entirely to his art. He has
        exhibited a handful of his 3000+ original drawings and 350+ oil
        paintings in local exhibits in recent years, but is otherwise unknown.
        His efforts to expose his work to the public have been minimal, indeed,
        in stark contrast to the phenomenal energy he pours into his work. Like
        that young boy who fell in love with the first color markers and sketch
        pads he ever laid eyes on, Dennis today does not really want to do much
        of anything except create. (Yes, the typical eccentric artist — he
        shrinks from the idea of commercialization). Very recently, friends and
        family who admire his work have begun efforts to help expose his work to
        the art world. This site represents the first part of those efforts.</p>
    </div>
  );
}

export default About;
