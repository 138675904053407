import * as React from "react";
import "../../style/success-message.css";
import {useEffect} from "react";

interface ErrorProps {
  message: string,
  title: string,
  close: () => void,
}

function SuccessMessage({message, title, close}: ErrorProps) {
  useEffect(() => {
    const escape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close();
      }
    }

    document.addEventListener("keydown", escape, false);

    return () => {
      document.removeEventListener("keydown", escape, false);
    };
  }, [close]);

  return (
    <div className={'success-message'}>
      <span onClick={() => close()} className={'close-button'}>x</span>
      <div className={'title'}>{title}</div>
      <div className={'message'}>{message}</div>
    </div>
  )
}

export default SuccessMessage;
