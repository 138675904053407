import * as React from "react";
import useTitle from "../../hooks/useTitle";
import artwork from "../../assets/artwork.json";
import getArtByType from "../../util/getArtByType";
import Gallery from "../components/Gallery";

const drawings = getArtByType(artwork, 'drawing');

function Drawings() {
  useTitle('Art Gallery');
  return (<Gallery art={drawings} />);
}

export default Drawings;
