import * as React from "react";
import useTitle from "../../hooks/useTitle";
import getArtByType from "../../util/getArtByType";
import artwork from "../../assets/artwork.json";
import Gallery from "../components/Gallery";

const paintings = getArtByType(artwork, 'painting');

function Paintings() {
  useTitle('Art Gallery');
  return (<Gallery art={paintings}/>);
}

export default Paintings;
